<template>
    <div class="download">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="dne-app">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-8 col-lg-6">
                        <h2 class="title-secondary">
                            DNE <span>A</span>pp
                        </h2>

                        <div class="glass">
                            <div class="description" v-html="getMyLanguage('Home','text.dne-pay')"></div>
                        </div>

                        <div class="download-app">
                            <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                                <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                            </a>
                            
                            <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                                <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>



<script>
   import Header from "@/components/Header"

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        components: {
            Header
        },

        data(){
            return{
                page: "home",
                flagSelected: "en",

                isMobile: false,
            }
        },

        mounted(){
            this.detectDevice()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            detectDevice() {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                if (/android/i.test(userAgent)) {
                    window.location.href = "https://play.google.com/store/apps/details?id=com.dneecosystem.app";
                } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    window.location.href = "https://apps.apple.com/pt/app/dne-ecosystem/id6466728703";
                } else {
                    this.isMobile = false;
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>


<style src="./custom-dne-app.scss" lang="scss" scoped />