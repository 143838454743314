<template>
    <div class="load-purchase" v-if="load">
        <div class="load load-200"></div>
    </div>

    <div class="error" v-if="!load && errorsPost.dataPurchase">
        <div class="box-error">
            <h3 class="title-secondary">Houve um erro</h3>
            <p>O Plugin DNE Pay não está configurado corretamente</p>
        </div>
    </div>

    <div class="plugin" v-if="!load && !errorsPost.dataPurchase">
        <header v-if="dataPurchase.layout.header">
            <div class="cont-header">
                <img src="~@/assets/images/header/dne-pay.svg" class="logo-dne-pay" alt="DNE Pay">
            </div>
        </header>

        <div class="cont-plugin">
            <div class="column">
                <div class="box items">
                    <div class="item"
                        :class="{multi: dataPurchase.items.length > 1}"
                        v-for="(listItem, index) in dataPurchase.items"
                        :key="index">
                        
                        <div class="photo">
                            <img src="https://cdn.dnemarket.com/product_variant_attachment_default.png" v-if="!listItem.image_url">
                            <img :src="listItem.image_url" :alt="listItem.name" v-else>
                        </div>

                        <div class="details">
                            <h3>{{listItem.name}}</h3>
                            
                            <div class="variables">
                                <span>{{ productDescription(listItem.description, 115) }}</span>
                            </div>

                            <div class="price">
                                <div class="cashback">
                                    <small>Cashback</small>
                                    <h3>{{listItem.cashback.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</h3>
                                </div>

                                <div class="prices">
                                    <span>{{ listItem.price.toLocaleString("pt-PT", {style:"currency", currency:dataPurchase.instrument.instrument.substring(0,3)}) }}</span>
                                    <h3>{{listItem.amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="total" v-if="dataPurchase.items.length > 1">
                        <div>
                            <strong>Total</strong>
                            <span>{{ dataPurchase.price.toLocaleString("pt-PT", {style:"currency", currency:dataPurchase.instrument.instrument.substring(0,3)}) }}</span>
                            <h3>{{ dataPurchase.amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 }) }}</h3>
                        </div>
                        
                        <div class="cashback">
                            <small>Cashback</small>
                            <h3>{{ dataPurchase.cashback.toLocaleString("pt-PT", { maximumFractionDigits: 4 }) }}</h3>
                        </div>
                    </div>
                </div>

                <div class="box box-pay-qrcode">
                    <img src="~@/assets/images/header/dne-app.svg" class="logo-dne-app" alt="DNE App">

                    <div class="pay-qrcode">
                        <div class="inf">
                            <p>Faça a leitura do QR Code pela sua aplicação <strong>DNE Ecosystem</strong>.</p>
                            <!-- <a href="#" target="_blank" class="btn-secondary">Saiba mais</a> -->
                        </div>

                        <div class="box-qrcode">
                            <div class="qrcode">
                                <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                            </div>
                            <!-- <pre>{{dataPurchase.qr_code}}</pre> -->
                        </div>
                    </div>

                    <div class="download-app">
                        <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                            <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                        </a>
                        
                        <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                            <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                        </a>
                    </div>
                </div>
            </div>

            <div class="column">

                <!-- LOGIN -->
                <div class="box login" v-if="!statusUserLogin">
                    <div class="box-login" v-if="loginOne">
                        <h2>Não tenho o APP</h2>
                        <p>Aceda a sua conta e siga os próximos passos.</p>

                        <input type="email" class="form formEmailLogin" imputemode="email" id="emailLogin"
                            @keyup.enter="loginPlatform()"
                            placeholder="E-mail"
                            v-model="sign.username">
                        
                        <div class="enterPass">
                            <input type="password" class="form formPassLogin"
                                v-if="!showPass"
                                @keyup.enter="loginPlatform()"
                                placeholder="Senha"
                                v-model="sign.password">

                            <input type="text" class="form formPassLogin"
                                v-if="showPass"
                                @keyup.enter="loginPlatform()"
                                placeholder="Senha"
                                v-model="sign.password">

                            <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                            <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                        </div>

                        <div class="btn-remember-password" @click="loginResetPass">
                            Esqueci minha senha
                        </div>
                        
                        <div class="btn-primary btn-disabled" v-if="sign.username.length <= 5 || sign.password.length <= 7">
                            Entrar
                        </div>

                        <div class="btn-primary" @click="loginPlatform()" v-if="!stepLogin.load && sign.username.length > 5 && sign.password.length > 7">
                            Entrar
                        </div>

                        <div class="btn-primary btn-loading" v-if="stepLogin.load"></div>


                        <!-- REGISTER -->
                        <div class="register">
                            <p>Ainda não faz parte do <strong>Ecossistema DNE</strong></p>

                            <a :href="`${VUE_APP_PLATFORM_URL}${dataPurchase.store.language}/account/register?utm_content=${dataPurchase.store.account_id}&utm_source=dne-pay&utm_campaign=plugin`" target="_blank" class="btn-secondary btn-register">
                                Registar agora
                            </a>
                        </div>
                    </div>


                    <!-- REMEMBER PASS -->
                    <div class="box-login-remember" v-else>
                        <div v-if="loginRemember && (!rememberSend || !rememberError)">
                            <h2>Recuperar senha</h2>
                            <p>Informe seu e-mail abaixo para seguir com a recuperação da senha.</p>
                            <input type="text" class="form formEmailLogin" id="emailRemember"
                                v-model="emailRemember.email"
                                placeholder="E-mail registado">

                            <button class="btn-primary"
                                @click="rememberPass()"
                                v-if="!emailRemember.load">
                                
                                Lembrar
                            </button>

                            <div class="btn-primary load" v-if="emailRemember.load"></div>
                        </div>

                        <div v-if="!loginRemember && rememberSend">
                            <h2>Sucesso</h2>

                            <div class="rememberPass rememberSuccess">
                                <p>Se esta conta existir, será enviado um e-mail para alterar a senha.</p>

                                <div class="btn-back" @click="backLogin()">Voltar</div>
                            </div>
                        </div>
                        
                        <div v-if="!loginRemember && rememberError">
                            <h2>Houve um erro</h2>

                            <div class="rememberPass rememberError">
                                <p>Ocorreu um erro. Por favor tente mais tarde.</p>

                                <div class="btn-back" @click="backLogin()">Voltar</div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- LOGGED -->
                <div class="box logged" id="loginScroll" v-else>
                    <div class="box-logged">

                        <div class="header-resume">
                            <div class="logo" v-if="dataPurchase.store.logo_url">
                                <img :src="dataPurchase.store.logo_url" :alt="dataPurchase.store.name">
                            </div>

                            <h3>{{dataPurchase.store.name}}</h3>
                        </div>

                        <h2>Resumo</h2>

                        <div class="resume">
                            <div class="item"
                                :class="{multi: dataPurchase.items.length > 1}"
                                v-for="(listItem, index) in dataPurchase.items"
                                :key="index">

                                <p>
                                    <strong>{{listItem.name}}</strong>
                                    <small>{{ productDescription(listItem.description, 200) }}</small>
                                </p>

                                <div class="values">
                                    <div class="cashback">
                                        <small>Cashback</small>
                                        <h3>{{ listItem.cashback.toLocaleString("pt-PT", { maximumFractionDigits: 4 } )}}</h3>
                                    </div>

                                    <div class="price">
                                        <p>
                                            <strong>{{ listItem.price.toLocaleString("pt-PT", {style:"currency", currency:dataPurchase.instrument.instrument.substring(0,3)}) }}</strong>
                                        </p>

                                        <h3>{{ listItem.amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 }) }}</h3>
                                    </div>
                                </div>
                            </div>


                            <!-- BALANCE / GENERATE CODE -->
                            <div class="load" v-if="loadBalance"></div>

                            <div class="balance" v-if="!loadBalance && !errorsGet.dataWallet">
                                <div class="values">
                                    <small>Valor disponível</small>
                                    <div class="price">
                                        <h3>{{totalBalance}}</h3>
                                    </div>
                                </div>

                                <div class="btn-primary getCode" v-if="totalBalance < dataPurchase.amount" @click="purchaseDNEToken()">
                                    <span>
                                        Comprar
                                        <small>DNE Token</small>
                                    </span>
                                </div>
                                
                                <div class="btn-primary getCode" v-if="(totalBalance >= dataPurchase.amount) && !flowSMS.generated && !flowSMS.load" @click="createPay()">
                                    <span>
                                        Validar
                                        <small>Gerar código</small>
                                    </span>
                                </div>
                                
                                <div class="load" v-if="flowSMS.load"></div>
                            </div>
                            
                        </div>
                    </div>
                </div>


                <!-- 2FA SMS CODE -->
                <div class="box second-factor" id="smsCodeScroll"
                    :class="flowSMS.status"
                    v-if="flowSMS.status != false">

                    <div class="sms-code" v-if='flowSMS.status == "getCode"'>
                        <h2>Código de verificação</h2>

                        <p v-if="!flowSMS.load">Informe o código que enviamos para o seu telemóvel.</p>

                        <div class="alert" v-if="flowSMS._2fa_tokenShow && !flowSMS.load">
                            Foi enviado um código SMS para o número
                            <strong>{{flowSMS.msg.ddi}} {{ maskedPhone(flowSMS.msg.phone) }}</strong>
                        </div>

                        <div class="sms-confirm" v-if="!flowSMS.load">
                            <div class="tooltip-error" v-if="errorsPost.toPay != false">Informe o código correto</div>

                            <input type="text" class="form" inputmode="numeric"
                                :class="{error: errorsPost.toPay}"
                                v-model="payData._2fa_token"
                                v-mask="'######'"
                                placeholder="_ _ _ _ _ _"
                                maxlength="6">

                            <div class="btn-primary btn-disabled btn-pay" v-if="payData._2fa_token.length < 6">
                                <span>Pagar</span>
                                <h3>{{dataPurchase.qr_code.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</h3>
                            </div>

                            <div class="btn-primary btn-pay" v-if="payData._2fa_token.length === 6" @click="toPay()">
                                <span>Pagar</span>
                                <h3>{{dataPurchase.qr_code.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</h3>
                            </div>
                        </div>

                        
                        <div class="load" v-if="!flowSMS._2fa_tokenShow && flowSMS.load"></div>

                        <div class="resend" v-if="flowSMS._2fa_tokenShow && !flowSMS.load">
                            <div class="btn-resend resend-load" v-if="!flowSMS.btnResendSMS">Reenviar código em {{flowSMS.countDownResendCode}}s</div>
                            <div class="btn-resend" v-if="flowSMS.btnResendSMS" @click="getCodePay()">Reenviar código</div>
                        </div>
                    </div>

                    <div v-if='flowSMS.load && flowSMS.status == "validating"'>
                        <h2>Código a validar</h2>
                        <div class="load"></div>
                    </div>


                    <!-- SMS CODE VALIDATED -->
                    <div class="box-code-validated result-flowPay" v-if='flowSMS.status == "validated"'>
                        <h2>Código validado</h2>

                        <div class="load" v-if='flowSMS.load && flowSMS.status == "validated"'></div>
                    </div>
                    
                    <div class="load" v-if='flowSMS.success == "validated" && flowSMS.load'></div>


                    <!-- SUCCESS REGISTER PAYMENT -->
                    <div class="success result-flowPay" id="successPayment" v-if='flowSMS.status == "success"'>
                        <h2>Sucesso</h2>
                        <p><strong>Pagamento realizado com sucesso.</strong></p>

                        <div class="obs">Acesse sua conta e acompanhe seu pedido.</div>
                    </div>


                    <!-- ERROR REGISTER PAYMENT -->
                    <div class="error result-flowPay" v-if='flowSMS.status == "failure"'>
                        <h2>Houve um erro</h2>
                        <p>Houve um erro ao finalizar o pagamento</p>

                        <div class="btn-primary">Tentar novamente</div>
                    </div>
                </div>
            </div>
        </div>

        <footer v-if="dataPurchase.layout.footer">
            <div class="cont-footer">
                <img src="~@/assets/images/header/logo-dne.svg" class="logo-dne" alt="Digital New Economy">
            </div>
        </footer>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import apiPlatform from '@/config/apiPlatform.js'
    import apiPayPlugin from '@/config/apiPayPlugin.js'
    import apiPay from '@/config/apiPay.js'
    import QrcodeVue from 'qrcode.vue'

    export default {
        components: {
            QrcodeVue,
        },

        data(){
            return {
                flagSelected: "en",
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,

                load: true,

                qrCode:{
                    value: "",
                    size: 260
                },

                layout: {
                    header: false,
                    footer: false
                },

                parameters: {
                    external_id: "",
                    campaign_id: "",
                    layout: {},
                    store_id: "",
                    instrument: "",
                    country: "",
                    items: [
                        {
                            external_id: "",
                            product_variant_id: "",
                            name: "",
                            description: "",
                            image_url: "",
                            price: ""
                        }
                    ]
                },

                dataPurchase: {},
                dataWallet: {},

                loadBalance: true,
                totalBalance: "",

                errorsPost: {
                    dataPurchase: false,
                    toPay: false
                },
                
                errorsGet: {
                    dataWallet: false,
                },

                newtk: "",
                linkInternacional: "",

                loginActive: false,
                logoutActive: false,
                
                loginOne: true,
                loginTwo: false,
                loginRemember: false,
                showPass: false,
                
                emailRemember: {
                    load: false,
                    email: ''
                },
                rememberSend: false,
                rememberError: false,
                
                dataLogin: '',
                dataAccount: [],
                
                sign: {
                    "username": "",
                    "password": ""
                },
                
                stepLogin: {
                    "load": false
                },
                
                validate: {
                    "tooltipUsername": false,
                    "tooltipPassword": false
                },
                
                statusUserLogin: "",

                loginSource: "",

                hasToken: "",
                token: "",

                dataMerchant: {},
                storeReferenceActive: 0,

                dataPhaseCurrent: "",

                flowToPay: {},

                otp: {
                    hash: ""
                },

                flowSMS: {
                    load: false,
                    phone: "",
                    
                    generated: false,
                    status: false,
                    
                    countDownResendCode: 0,
                    btnResendSMS: false,
                    _2fa_tokenShow: false
                },

                payData: {
                    _2fa_token: ''
                },

                dataToPay: {
                    request_type: "request_2fa",
                    _2fa_provider: "sms",
                    request_id: "00000000-0000-0000-0000-000000000000",
                    _2fa_token: 0,
                    source_account_wallet_id: "",
                    destiny_account_wallet_id: "",
                    asset_short: "DNE",
                    quantity: 0,
                    description: "string"
                }
            }
        },

        async mounted() {
            if ( this.$route.params.urlid ){
                this.checkPurchaseUrlID()
            } else {
                this.checkPurchase()
            }

            this.checkToken()
        },


        methods: {
            checkPurchaseUrlID(){
                apiPayPlugin.get(`/api/v2/pay/plugin/checkout/${this.$route.params.urlid}`)
                .then(response => {
                    this.dataPurchase = response.data
                    console.log(this.dataPurchase)
                    this.qrCode.value = JSON.stringify(response.data.qr_code)
                    
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
                .catch(error => {
                    this.errorsPost.dataPurchase = error
                    this.$toast.error("Houve um erro")

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },


            checkPurchase(){
                const urlParams = new URLSearchParams(window.location.search)

                this.parameters.external_id = urlParams.get('external_id')
                this.parameters.campaign_id = urlParams.get('campaign_id')
                this.parameters.store_id = urlParams.get('store_id')
                this.parameters.instrument = urlParams.get('instrument')
                this.parameters.country = urlParams.get('country')


                // LAYOUT
                const layoutJson = urlParams.get("layout");
                if (layoutJson) {
                    try {
                        this.parameters.layout = JSON.parse(decodeURIComponent(layoutJson));
                    } catch (error) {
                        console.error("Erro ao decodificar o layout:", error);
                        this.parameters.layout = {};
                    }
                } else {
                    this.parameters.layout = {};
                }


                // ITEMS
                const itemsJson = urlParams.get('items')
                if (itemsJson) {
                    try {
                        this.parameters.items = JSON.parse(decodeURIComponent(itemsJson))
                    } catch (error) {
                        console.error('Erro ao decodificar os itens:', error)
                    }
                } else {
                    this.parameters.items = []
                }


                apiPayPlugin.post(`/api/v2/pay/plugin/checkout`, this.parameters)
                .then(response => {
                    this.dataPurchase = response.data
                    console.log(this.dataPurchase)
                    this.qrCode.value = JSON.stringify(response.data.qr_code)
                    
                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
                .catch(error => {
                    this.errorsPost.dataPurchase = error
                    this.$toast.error("Houve um erro")

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },


            checkToken(){
                const tokenValue = localStorage.getItem('accessToken')

                if ( tokenValue != undefined || tokenValue != null ){
                    var base64Url = localStorage.getItem("accessToken").split('.')[1]
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
                    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    }).join(''));
    
                    this.token = JSON.parse(jsonPayload)

                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    if (this.token.exp && this.token.exp > currentTimestamp) {
                        this.statusUserLogin = true
                        
                        this.flowSMS.msg = JSON.parse(localStorage.getItem("msg"))

                        this.checkBalance()

                        setTimeout(() => {
                            this.scrollPage("loginScroll")
                        }, 1500);
                    } else {
                        console.log("Expired token");
                        this.statusUserLogin = false
                        
                        localStorage.removeItem('accessToken');
                        this.$store.commit('profile', "")
                        this.$store.commit('loginStatus', false)
                    }
                }

                this.$store.commit('loginStatus', false)
            },

            
            loginPlatform(){
                const loginPlatform = this.sign;
                this.stepLogin.load = true

                apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    this.statusUserLogin = true
                    // this.checkBalance()
                    // this.settingsLogin = response.data
                    
                    localStorage.setItem('accessToken',response.data.accessToken);
                    localStorage.setItem("msg", JSON.stringify({
                        ddi: response.data.profile.profile_phone_country_ddi,
                        phone: response.data.profile.profile_phone_number
                    }));


                    setTimeout(() => {
                        window.location.reload()
                    }, 300);
                })
                .catch(error => {
                    this.stepLogin.load = false

                    console.clear()
                    console.log(error);

                    let errorsLogin = JSON.stringify(error.response.status)
                    this.loginErrors = errorsLogin

                    if ( errorsLogin == 400 ){
                        this.$toast.error("Email ou senha inválidos");
                    } else{
                        this.$toast.error("Erro na autenticação");
                    }
                })
            },

            loginResetPass(){
                this.loginRemember = true,
                this.loginOne = false,
                this.loginTwo = false
                this.emailRemember.load = false
            },
            
            backLogin(){
                this.loginRemember = false,
                this.loginOne = true,
                this.loginTwo = true
            },

            rememberPass(){
                this.emailRemember.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    this.rememberSend = true
                    this.loginRemember = false

                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.rememberError = true
                    this.loginRemember = false

                    console.log(error.message);
                    this.$toast.error(error.response.data)
                })
            },


            // BALANCE
            checkBalance(){
                apiPay.get('/api/v1/pay/wallet/balances/DNE')
                .then(response => {
                    this.dataWallet = response.data

                    setTimeout(() => {
                        this.totalBalance = this.dataWallet.source_wallets.filter(wallet => wallet.account_wallet_type_code == "spot")[0].balance
    
                        this.flowToPay.source_account_wallet_id = this.dataWallet.source_wallets.filter(wallet => wallet.account_wallet_type_code == "spot")[0].account_wallet_id
                        this.flowToPay.destiny_account_wallet_id = this.dataPurchase.qr_code.AccountWalletId
                        this.flowToPay.asset_short = this.dataPurchase.qr_code.AssetShort
                        this.flowToPay.amount = this.dataPurchase.qr_code.Amount
                        this.flowToPay.description = this.dataPurchase.qr_code.Details
                        this.flowToPay.instrument_price_hash = this.dataPurchase.qr_code.instrument_price_hash
                    }, 100);
                    
                    setTimeout(() => {
                        this.loadBalance = false
                    }, 500);
                })
                .catch(error => {
                    this.errorsGet.dataWallet = error
                    console.error(error)

                    setTimeout(() => {
                        this.loadBalance = false
                    }, 500);
                })
            },

            
            // PURCHASE DNE TOKEN
            purchaseDNEToken(){
                this.newtk = localStorage.getItem('accessToken')
                this.linkInternacional = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space/purchase`
                window.open(this.linkInternacional, '_blank');
            },


            // CREATE PAY
            createPay(){
                this.flowSMS.load = true

                apiPay.post('/api/v2/pay/tx/payment', this.flowToPay)
                .then(response => {
                    console.log(response.data)
                    this.otp.hash = response.data.hash

                    this.flowSMS.generated = true
                    this.flowSMS.status = "getCode"
                    this.flowSMS._2fa_tokenShow = false

                    this.flowSMS.load = false
                    
                    setTimeout(() => {
                        this.flowSMS._2fa_tokenShow = true
                        this.flowSMS.btnResendSMS = false
                        this.flowSMS.countDownResendCode = 10
                        this.countDownPayData()
                        this.scrollPage("smsCodeScroll")
                    }, 1000);
                })
                .catch(error => {
                    console.error(error)
                    
                    this.modal.status = "invalid"
                    this.modal.load = false
                })
            },

            countDownPayData() {
                if(this.flowSMS.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSMS.countDownResendCode -= 1
                        this.countDownPayData()
                    }, 1000)
                } else{
                    this.flowSMS.btnResendSMS = true
                }
            },

            toPay(){
                this.flowSMS.load = true
                this.flowSMS.status = "validating"

                apiPay.post("api/v2/pay/otp", {"hash": this.otp.hash, "code": this.payData._2fa_token})
                .then(response => {
                    this.registerId = response.data.id
                    
                    setTimeout(() => {
                        this.flowSMS.status = "success"                        
                        this.flowSMS.load = false

                        this.checkBalance()
                    }, 1000);
                        
                    setTimeout(() => {
                        this.scrollPage("successPayment")
                    }, 1500);
                })
                .catch(error => {
                    this.errorsPost.toPay = error.response.status
                    this.flowSMS.load = false

                    this.flowSMS.status = "getCode"
                    this.$toast.error("Código inválido")

                    setTimeout(() => {
                        this.errorsPost.toPay = false
                    }, 3000);
                })
            },

            productDescription(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + "...";
                }
                return text;
            },

            maskedEmail(email) {
                if (!email) return '';
                const [localPart, domain] = email.split('@');
                if (!localPart || !domain) return email;
                const visibleStart = localPart.slice(0, 2);
                const maskedLocalPart = `${visibleStart}****`;
                return `${maskedLocalPart}@${domain}`;
            },


            maskedPhone(phoneNumber) {
                if (!phoneNumber) return ''
                const visibleStart = phoneNumber.slice(0, 2)
                const visibleEnd = phoneNumber.slice(-2)

                return `${visibleStart}****${visibleEnd}`
            },


            scrollPage(id){
                const anc = document.getElementById(id);
                
                setTimeout(() => {
                    anc.scrollIntoView({ behavior: "smooth" });
                }, 1000);
            }
        }
    }
</script>


<style src="./custom-plugin.scss" lang="scss" scoped />