<template>
    <header :class="{develop: VUE_APP_DEVELOP == 'develop'}">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4 col-xl-2 col-logo">
                    <a :href='`/${flagSelected}#dne`' class="logo" v-if="dataSource == 'home'">
                        <img src="@/assets/images/header/dne-pay.svg" alt="DNE Pay">
                    </a>
                    
                    <a :href='`/${flagSelected}/client-space/`' class="logo" v-else>
                        <img src="@/assets/images/header/dne-pay.svg" alt="DNE Pay">
                    </a>
                </div>

                <div class="col-8 col-xl-10">
                    <div class="row-master">
                        <div class="col-menu">
                            <MenuPay :dataSource="dataSource" />
                        </div>

                        <div class="col-lang-login">
                            <Login />

                            <div class="openSettings" @click="openSidebar('settings')">
                                <div class="flag">
                                    <div class="load-flag" v-if="!settings.flagShow"></div>
                                    <!-- <img v-if="settings.flagShow" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${settings.flagShipping}/flags`"> -->
                                    <img v-if="settings.flagShow" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${settings.flagSelected}/flags`">
                                </div>
                                
                                <small v-if="settings.languageName">
                                    {{settings.languageName.slice(0, 3)}}<br>{{settings.currencyShort}}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>


    <!-- SIDEBAR SETTINGS -->
    <div class="sidebar" v-if="sidebar.settings">
        <div class="closeSidebar" @click="closeSidebar()"></div>
        <Settings />
    </div>
    <div class="overlay" v-if="sidebar.settings" @click="closeSidebar()"></div>
</template>


<script>
    import MenuPay from '@/components/MenuPay.vue';
    import Settings from '@/components/Settings.vue';
    import Login from '@/components/Login.vue';

    export default {
        props: {
            dataSource: String
        },

        components: {
            MenuPay,
            Settings,
            Login
        },

        data (){
            return {
                flagSelected: "en",
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,

                sidebar: {
                    settings: false,
                },

                settings: {
                    flagShow: false,
                    // flagShipping: "",
                    flagSelected: "",
                    languageName: "",
                    currencyShort: ""
                },
            }
        },

        mounted(){
            setTimeout(() => {
                this.checkSettings()

                if ( !localStorage.getItem("flagLanguage") ){
                    localStorage.setItem("flagLanguage","51021d86-49e9-4beb-804b-dfadf7a51300")
                } else {
                    this.settings.flagSelected = localStorage.getItem('flagLanguage')
                }

                // if ( !localStorage.getItem("shippingFlag") ){
                //     localStorage.setItem("shippingFlag","58bfc699-7e06-4362-3445-c598e9232900")
                // } else {
                //     this.settings.flagShipping = localStorage.getItem('shippingFlag')
                // }
            }, 1000);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                this.settings.languageName = localStorage.getItem("Language")
                this.settings.flagSelected = localStorage.getItem('flagLanguage')

                setTimeout(() => {
                    this.settings.flagShow = true
                }, 1500);
            },

            openSidebar(){
                this.sidebar.settings = true
                document.body.classList.add('body-settings-opened')
            },

            closeSidebar(){
                this.sidebar.settings = false
                
                document.body.classList.remove('body-settings-opened')
            },

            // openSidebar(value, profile){
            //     if ( value == "menu" ){
            //         this.sidebar.menu = true
            //         document.body.classList.add('body-sidebar-opened')
            //     }

            //     if ( value == "settings" ){
            //         this.sidebar.settings = true
            //         document.body.classList.add('body-settings-opened')
            //     }

            //     if ( value == "login" ){
            //         this.sidebar.login = true
                    
            //         setTimeout(() => {
            //             bus.emit('settings-profile',profile);
            //         }, 100);
            //     }
            // },
            
            // closeSidebar(){
            //     this.sidebar.menu = false
            //     this.sidebar.settings = false
            //     this.sidebar.login = false
                
            //     document.body.classList.remove('body-sidebar-opened')
            //     document.body.classList.remove('body-settings-opened')
            // },

            checkSettings(){
                setTimeout(() => {
                    this.settings.flagShipping = localStorage.getItem("shippingFlag")
                    this.settings.languageName = localStorage.getItem("Language")
                    this.settings.currencyShort = localStorage.getItem("instrumentTickerShort")
                    this.settings.flagShow = true
                }, 200);
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
    }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />