<template>
    <div class="sidebar-cont">
        <h5 class="title-settings">{{ getMyLanguage("Settings","title") }}</h5>

        <!-- LANGUAGES -->
        <div class="languages"
            :class="{active: flowSettings.showLanguages}"
            v-if="!flowSettings.showCountries && !flowSettings.showCurrencies">
            
            <h5 v-if="!flowSettings.showLanguages && !updated">
                {{getMyLanguage("Settings","select-language.title")}}
            </h5>

            <div class="open-list"
                @click="droplistLanguages(true)"
                v-if="!flowSettings.showLanguages && !updated">

                <div class="item">
                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selected.language.flag}/flags`">
                    <span>{{ languagesList(selected.language.code) }}</span>
                </div>
            </div>
            
            <div class="updated" v-if="updated"></div>

            <div class="close-list"
                @click="flowSettings.showLanguages = false"
                v-if="flowSettings.showLanguages"></div>

            <h4 class="title-secondary" v-if="flowSettings.showLanguages">
                {{getMyLanguage("Settings","select-language.title")}}
            </h4>
            
            <div v-if="flowSettings.showLanguages">
                <div class="closeAutocomplete closeDropListLanguages" @click="droplistLanguages(false)"></div>

                <input type="text" id="searchLanguage" class="form" @keyup="filterListLanguages()">
                
                <ul class="list" id="drop-list-languages">
                    <li class="item"
                        :class="{selected: itemList.code == selected.language.code}"
                        v-for="(itemList,index) in langsTranslated"
                        :key="index"
                        @click="changeLanguage(itemList.code, itemList.name, itemList.flag)">
                        
                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                        <a>{{ languagesList(itemList.code) }}</a>
                    </li>
                </ul>
            </div>
        </div>


        <!-- COUNTRIES -->
        <!-- <div class="countries"
            :class="{active: flowSettings.showCountries}"
            v-if="!flowSettings.showLanguages && !flowSettings.showCurrencies">

            <h5 v-if="!flowSettings.showCountries">
                {{getMyLanguage("Settings","ship-to.title")}}
            </h5>

            <div class="open-list"
                :class="{newCountry: settings.country.IsoCode && selected.country.IsoCode != settings.country.IsoCode}"
                @click="droplistCountries(true)"
                v-if="!flowSettings.showCountries">

                <div class="item" v-if="settings.country.IsoCode">
                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${settings.country.flag}/flags`" v-if="settings.country.flag">
                    <span>{{ countryTranslate(settings.country.IsoCode) }}</span>
                </div>
                
                <div class="item" v-else-if="selected.country.IsoCode">
                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selected.country.Flag}/flags`">
                    <span>{{ countryTranslate(selected.country.IsoCode) }}</span>
                </div>
                
                <div class="item" v-else>
                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${settings.country.flag}/flags`" v-if="settings.country.flag">
                    <span>{{getMyLanguage("Settings","select-country")}}</span>
                </div>
            </div>

            <div class="close-list" @click="flowSettings.showCountries = false" v-if="flowSettings.showCountries"></div>

            <h5 class="title-secondary" v-if="flowSettings.showCountries">
                {{getMyLanguage("Settings","ship-to.title")}}
            </h5>
            
            <div v-if="flowSettings.showCountries">
                <div class="closeAutocomplete closeDropListCountry" @click="droplistCountries(false)"></div>

                <input type="text" id="deliveryCountry" class="form"
                    :class="{selected: settings.country.flag}"
                    @keyup="filterListCountries()"
                    :value="settings.country.IsoCode">
                
                <ul class="list" id="drop-list-countries">
                    <li class="item"
                        :class="{selected: itemList.IsoCode == selected.country.IsoCode}"
                        v-for="(itemList,index) in countriesTranslated"
                        :key="index"
                        @click="changeShipping(itemList.Id, itemList.Flag, itemList.IsoCode)">

                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                        <a>{{ countryTranslate(itemList.IsoCode) }}</a>
                    </li>
                </ul>
            </div>
        </div> -->


        <!-- CURRENCIES -->
        <div class="currencies"
            :class="{active: flowSettings.showCurrencies}"
            v-if="!flowSettings.showCountries && !flowSettings.showLanguages">

            <h5 v-if="!flowSettings.showCurrencies">
                {{getMyLanguage("Settings","currency.title")}}
            </h5>

            <div class="open-list"
                :class="{newTicker: settings.currency.ticker && selected.currency.ticker != settings.currency.ticker}"
                @click="droplistCurrencies(true)"
                v-if="!flowSettings.showCurrencies">

                <div class="item" v-if="settings.currency.ticker">
                    <span class="currency">
                        <i :class="settings.currency.symbol">
                            {{settings.currency.symbol}}
                        </i>

                        <span>
                            <strong>
                                {{settings.currency.name}}
                                <small>({{settings.currency.short}})</small>
                            </strong>

                            <span class="quote">
                                <strong class="current">
                                    {{(settings.currency.price).toLocaleString(selected.language, {style: "currency", currency: settings.currency.short})}}
                                </strong>

                                <strong class="dne"> >> 1 DNE TOKEN</strong>
                            </span>
                        </span>
                    </span>
                </div>
                
                <div class="item" v-else-if="selected.currency.ticker">
                    <span class="currency">
                        <i :class="selected.currency.quote_asset.symbol">
                            {{selected.currency.quote_asset.symbol}}
                        </i>

                        <span>
                            <strong>
                                {{getMyLanguage("Currency","asset." + selected.currency.quote_asset.short)}}
                                <small>({{selected.currency.quote_asset.short}})</small>
                            </strong>

                            <span class="quote">
                                <strong class="current">
                                    {{(selected.currency.price.price).toLocaleString(selected.language, {style: "currency", currency: selected.currency.quote_asset.short})}}
                                </strong>

                                <strong class="dne"> >> 1 DNE TOKEN</strong>
                            </span>
                        </span>
                    </span>
                </div>
                
                <div class="item" v-else>
                    <span>{{ getMyLanguage("Settings","currency.title") }}</span>
                </div>
            </div>

            <div class="close-list"
                @click="flowSettings.showCurrencies = false"
                v-if="flowSettings.showCurrencies"></div>

            <h4 class="title-secondary" v-if="flowSettings.showCurrencies">
                {{getMyLanguage("Settings","currency.title")}}
            </h4>
            
            <div v-if="flowSettings.showCurrencies">
                <div class="closeAutocomplete closeDropListCurrencies" @click="droplistCurrencies(false)"></div>

                <input type="text" id="searchCurrencies" class="form"
                    :class="{selected: settings.currency.ticker}"
                    @keyup="filterListCurrencies()"
                    :value="settings.currency.name">
                
                <ul class="list" id="drop-list-currencies">
                    <li class="item" :class="{selected: itemList.ticker == selected.currency.ticker}"
                        v-for="(itemList,index) in $store.state.allCurrencies"
                        :key="index"
                        @click="changeCurrencies(itemList.quote_asset.symbol, itemList.quote_asset.name, itemList.quote_asset.short, itemList.price.price, itemList.ticker)">
                        
                        <span class="currency">
                            <i :class="itemList.quote_asset.symbol">{{itemList.quote_asset.symbol}}</i>
                            <a>{{getMyLanguage("Currency","asset." + itemList.quote_asset.short)}} <small>({{itemList.quote_asset.short}})</small></a>
                        </span>
                    </li>
                </ul>
            </div>
        </div>


        <!-- SAVE SETTINGS -->
        <div class="btn-save"
            v-if="((settings.country.IsoCode && selected.country.IsoCode != settings.country.IsoCode)
                || (settings.currency.ticker && selected.currency.ticker != settings.currency.ticker))
                && !flowSettings.showLanguages
                && !flowSettings.showCountries
                && !flowSettings.showCurrencies">
                
            <div class="btn-primary" @click="saveSettings()">
                {{getMyLanguage("Settings","btn-apply")}}
            </div>
        </div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import langsList from "@/services/listLanguages.js";
    // import countriesList from "@/services/listCountries.js";

    import bus from '@/eventBus';
    
    export default{
        data() {
            return {
                flagSelected: "51021d86-49e9-4beb-804b-dfadf7a51300",
                flagShipping: "58bfc699-7e06-4362-3445-c598e9232900",
                codeLanguage: "",
                languageCode: "",
                currencyShort: "",

                flagShow: false,
                updated: false,
                dataLanguage: [],

                selected: {
                    language: "",
                    country: {
                        id: "",
                        flag: "",
                        name: "",
                        IsoCode: ""
                    },
                    currency: ""
                },

                flowSettings: {
                    showLanguages: false,
                    showCountries: false,
                    showCurrencies: false
                },

                settings: {
                    country: {
                        id: "",
                        flag: "",
                        name: "",
                        IsoCode: ""
                    },
                    currency: {
                        name: "",
                        short: "",
                        price: "",
                        ticker: ""
                    }
                },
            }
        },

        async mounted() {
            this.flagSelected = localStorage.getItem('flagLanguage')
            this.codeLanguage = localStorage.getItem('codeLanguage')
            this.flagShow = false


            // LOAD LANGUAGES
            this.loadLanguagesStore().then(() => {
                if ( localStorage.getItem("codeLanguage") ) {
                    const allLanguages = this.$store.state.allLanguages;

                    if ( Array.isArray(allLanguages) ) {
                        this.selected.language = allLanguages.filter(lang => lang.code == localStorage.getItem("codeLanguage"))[0];
                        this.languageCode = allLanguages.filter(lang => lang.code == localStorage.getItem("codeLanguage"))[0].name.slice(0, 3);
                    } else {
                        console.error("Error allLanguages");
                    }
                }
            });

            
            // LOAD COUNTRIES
            // this.loadCountriesStore().then(() => {
            //     if ( localStorage.getItem("shippingCountry") ) {
            //         const allCountries = this.$store.state.allCountries;

            //         if ( Array.isArray(allCountries) ) {
            //             this.selected.country = allCountries.filter(country => country.Name == localStorage.getItem("shippingCountry"))[0];
            //         } else {
            //             console.error("Error allCountries");
            //         }
            //     }
            // });


            // LOAD CURRENCIES
            this.loadCurrenciesStore().then(() => {
                if ( localStorage.getItem("instrumentTicker") ) {
                    const allCurrencies = this.$store.state.allCurrencies;

                    if ( Array.isArray(allCurrencies) ) {
                        this.selected.currency = allCurrencies.filter(curr => curr.ticker == localStorage.getItem("instrumentTicker"))[0];
                        this.currencyShort = this.selected.currency.quote_asset.short
                    } else {
                        console.error("Error allCurrencies");
                    }
                }
            });
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            
            // LANGUAGES
            languagesList(code){
                return langsList(code, this.$store.state.SelectLanguage.code)
            },

            orderLangs() {
                this.langsTranslated = [...this.$store.state.allLanguages];

                this.langsTranslated.forEach(lang => {
                    lang.translatedName = this.languagesList(lang.code);
                });

                this.langsTranslated.sort((a, b) => {
                    return a.translatedName.toLowerCase().localeCompare(b.translatedName.toLowerCase());
                });
            },
            
            
            // COUNTRIES
            // countryTranslate(code){
            //     return countriesList(code, this.$store.state.SelectLanguage.code)
            // },

            // ordercountries() {
            //     this.countriesTranslated = [...this.$store.state.allCountries];

            //     this.countriesTranslated.forEach(lang => {
            //         lang.translatedCountry = this.countryTranslate(lang.IsoCode);
            //     });

            //     this.countriesTranslated.sort((a, b) => {
            //         return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
            //     });
            // },


            changeLanguage(parCodeLanguage, parLanguage, parFlag){
                this.flagShow = false
                this.updated = true
                this.flowSettings.showLanguages = false

                localStorage.setItem('codeLanguage',parCodeLanguage)
                localStorage.setItem('Language',parLanguage)
                localStorage.setItem('flagLanguage',parFlag)

                this.codeLanguage = localStorage.getItem('codeLanguage')

                this.selected.language = this.$store.state.allLanguages.filter(lang => lang.code == localStorage.getItem("codeLanguage"))[0]
                this.languageCode = this.$store.state.allLanguages.filter(lang => lang.code == localStorage.getItem("codeLanguage"))[0].name.slice(0, 3);

                var url = window.location.pathname;
                var newUrl = url.replace(/\/[a-z]{2}\//, '/');
                var isHome = String(window.location.pathname).split("/")

                if ( isHome.length > 2 ){
                    newUrl = `/${parCodeLanguage}${newUrl}`
                } else {
                    newUrl = `/${parCodeLanguage}`
                }
                
                history.pushState(null, null, newUrl);

                this.flagSelected = parFlag
                this.$store.commit('preLanguage',parCodeLanguage)
                this.$store.commit('preLanguageFlag',parFlag)

                setTimeout(() => {
                    this.flagShow = true
                }, 500);

                setTimeout(() => {
                    this.updated = false
                }, 2000);
            },

            
            changeShipping(id, flag, name){
                this.settings.country.id = id
                this.settings.country.flag = flag
                this.settings.country.IsoCode = name
                
                this.flowSettings.showCountries = false
            },

            changeCurrencies(symbol, name, short, price, ticker){
                this.settings.currency.symbol = symbol
                this.settings.currency.name = name
                this.settings.currency.short = short
                this.settings.currency.price = price
                this.settings.currency.ticker = ticker
                this.flowSettings.showCurrencies = false
            },


            checkShipping(){
                const countryIsoCode = this.$store.state.allCountries.filter(ctry => ctry.IsoCode == localStorage.getItem("IsoCode"))
                const countryName = this.$store.state.allCountries.filter(ctry => ctry.Name == localStorage.getItem("shippingCountry"))

                if ( !countryIsoCode.length ){
                    if ( countryName.length ){
                        localStorage.setItem("IsoCode", this.$store.state.allCountries.filter(ctry => ctry.Name == countryName[0].Name)[0].IsoCode)
                        localStorage.setItem("shippingFlag", this.$store.state.allCountries.filter(ctry => ctry.Name == countryName[0].Name)[0].Flag)
                    } else {
                        localStorage.setItem("IsoCode", "PT")
                        localStorage.setItem("shippingFlag","58bfc699-7e06-4362-3445-c598e9232900")
                    }

                    window.location.reload(true)
                }

                if ( !countryName.length ){
                    if ( countryIsoCode.length ){
                        localStorage.setItem("shippingCountry", this.$store.state.allCountries.filter(ctry => ctry.IsoCode == countryIsoCode[0].IsoCode)[0].Name)
                        localStorage.setItem("shippingFlag", this.$store.state.allCountries.filter(ctry => ctry.IsoCode == countryIsoCode[0].IsoCode)[0].Flag)
                    } else {
                        localStorage.setItem("shippingCountry", "Portugal")
                        localStorage.setItem("shippingFlag","58bfc699-7e06-4362-3445-c598e9232900")
                    }

                    window.location.reload(true)
                }


                // COMPARE
                if ( countryIsoCode.length && countryName.length ){
                    if ( this.$store.state.allCountries.filter(ctry => ctry.IsoCode == countryIsoCode[0].IsoCode) ){
                        localStorage.setItem("shippingCountry", countryIsoCode[0].Name)
                        localStorage.setItem("shippingFlag", countryIsoCode[0].Flag)

                        // window.location.reload(true)
                        
                    } else if ( !this.$store.state.allCountries.filter(ctry => ctry.IsoCode == countryIsoCode[0].IsoCode) && this.$store.state.allCountries.filter(ctry => ctry.Name == countryName[0].Name)){
                        localStorage.setItem("shippingCountry", countryName[0].Name)
                        localStorage.setItem("shippingFlag", countryName[0].Flag)
                        
                        // window.location.reload(true)

                    } else {
                        localStorage.setItem("IsoCode", "PT")
                        localStorage.setItem("shippingCountry", "Portugal")
                        localStorage.setItem("shippingFlag","58bfc699-7e06-4362-3445-c598e9232900")
                        
                        // window.location.reload(true)
                    }
                    
                }
            },



            // LANGUAGES
            droplistLanguages(status){
                if ( status ){
                    this.flowSettings.showLanguages = true
                    
                    setTimeout(() => {
                        document.getElementById('searchLanguage').focus()
                    }, 10);
                } else {
                    this.flowSettings.showLanguages = false
                }
            },

            filterListLanguages() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("searchLanguage");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list-languages");
                li = ul.getElementsByTagName("li");
                
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },


            // COUNTRIES
            droplistCountries(status){
                setTimeout(() => {
                    this.flowSettings.showCountries = status
                }, 250)
                
                if ( status ){
                    this.flowSettings.showCountries = true

                    setTimeout(() => {
                        document.getElementById('deliveryCountry').focus()
                    }, 500);
                } else {
                    this.flowSettings.showCountries = false
                }
            },

            filterListCountries() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("deliveryCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list-countries");
                li = ul.getElementsByTagName("li");
                
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },
            
            
            // CURRENCIES
            droplistCurrencies(status){
                setTimeout(() => {
                    this.flowSettings.showCurrencies = status
                }, 250)
                
                if ( status ){
                    this.flowSettings.showCurrencies = true

                    setTimeout(() => {
                        document.getElementById('searchCurrencies').focus()
                    }, 500);
                } else {
                    this.flowSettings.showCurrencies = false
                }
            },

            filterListCurrencies() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("searchCurrencies");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list-currencies");
                li = ul.getElementsByTagName("li");
                
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

            saveSettings(){
                if ( this.settings.country.IsoCode && (this.selected.country.IsoCode != this.settings.country.IsoCode) ){
                    localStorage.setItem("shippingCountry",this.$store.state.allCountries.filter(ship => ship.IsoCode == this.settings.country.IsoCode)[0].Name)
                    localStorage.setItem("shippingFlag",this.settings.country.flag)
                    localStorage.setItem("IsoCode",this.settings.country.IsoCode)
                }
                
                if ( this.settings.currency.ticker && (this.selected.currency.ticker != this.settings.currency.ticker) ){
                    localStorage.setItem("instrumentTicker",this.settings.currency.ticker)
                    localStorage.setItem("instrumentTickerShort",this.settings.currency.short)
                }

                window.location.reload(true)
            },

            loadLanguagesStore() {
                return new Promise(resolve => {
                    const checkLanguages = () => {
                        if ( this.$store.state.allLanguages ) {
                            resolve();
                        } else {
                            setTimeout(checkLanguages, 100);
                        }
                    };

                    checkLanguages();
                });
            },
            
            // loadCountriesStore() {
            //     return new Promise(resolve => {
            //         const checkCountries = () => {
            //             if ( this.$store.state.allCountries ) {
            //                 resolve();
            //             } else {
            //                 setTimeout(checkCountries, 100);
            //             }
            //         };

            //         checkCountries();
            //     });
            // },

            loadCurrenciesStore() {
                return new Promise(resolve => {
                    const checkCurrencies = () => {
                        if ( this.$store.state.allCurrencies ) {
                            resolve();
                        } else {
                            setTimeout(checkCurrencies, 100);
                        }
                    };

                    checkCurrencies();
                });
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    // this.checkShipping()
                    this.orderLangs()
                    // this.ordercountries()
                }
            }
        },


        created() {

            // SETTINGS PROFILE
            bus.on("settings-profile", (value) => {
                setTimeout(() => {
                    this.changeLanguage(value.code, value.language, value.flagLanguage)
                    
                    localStorage.setItem("IsoCode",value.shippingIsoCode)
                    localStorage.setItem("shippingCountry",value.shippingCountry)
                    localStorage.setItem("shippingFlag",value.shippingFlag)
                    // localStorage.setItem("shippingFlag",this.$store.state.allCountries.filter(flag => flag.translatedCountry == value.shippingCountry)[0].Flag)

                    localStorage.setItem("instrumentTicker", value.instrumentTicker)
                    localStorage.setItem("instrumentTickerShort", value.tickerShort)
                    
                    window.location.reload(true)
                }, 100);
            })
        }
    }
</script>


<style src="@/assets/scss/_settings.scss" lang="scss" scoped />