// import vue from 'vue'
import { createStore } from 'vuex'
// import axios from 'axios'
// import api from '@/config/api.js'

export default createStore({
   state: {
      allLanguages: '',
      allCountries: '',
      allCurrencies: '',

      SelectLanguage: {
         code: '',
         flag: '',
         language: ''
      },

      profile: {},
      statusLogin: false,
      phaseCurrent: ''
   },
   
   mutations: {
      allLanguages(state, value){
         state.allLanguages = value
      },
      allCountries(state, value){
         state.allCountries = value
      },
      allCurrencies(state, value){
         state.allCurrencies = value
      },
      preLanguage(state,code){
         state.SelectLanguage.code = code
      },
      preLanguageFlag(state,flag){
         state.SelectLanguage.flag = flag
      },
      preLanguageName(state,language){
         state.SelectLanguage.language = language
      },
      changeLanguage(state, value){
         state.SelectLanguage = value
      },
      errorLogin(state, value){
         state.statusLogin = value
      },
      profile(state, value){
         state.profile = value
      },
      loginStatus(state, value){
         state.statusLogin = value
      },
      validatePhoneNumber(state, value){
         state.profile.profile_phone_number_verified = value
      },
      phaseCurrent(state, value){
         state.phaseCurrent = value
      }
   },
   actions: {
      // test ({commit}){
      //    return axios.get(api('api/v1/platform/language')).then((result) => {
      //       commit('changeLanguage',result.data)
      //    })
      // }
        
   },
   modules: {
      
   },
   getters: {
      
   }
})
