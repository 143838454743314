<template>
    <header class="header-store center" :class="{center: !dataSource.store_details}" :style="bannerStyle" v-if="dataSource.slug">
        <div class="logo">
            <img :src="dataSource.logo_attachment" class="store-logo"
                :class="{storeLogoBox: dataSource.logo_attachment_box}"
                :style="{ background: dataSource.logo_attachment_box ? dataSource.logo_attachment_hexa : 'none' }">
            
            <h2 class="store-name">{{dataSource.name}}</h2>
        </div>
    </header>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        props: {
            dataSource: {},
        },

        data(){
            return {
                modal: false,
                bannerStyle: ""
            }
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },
        },

        async mounted(){
            setTimeout(() => {
                if ( this.dataSource.banner_attachment ){
                    this.bannerStyle = `background: url(${this.dataSource.banner_attachment})`
                } else {
                    this.bannerStyle = 'background: #1f3561'
                }
            }, 1000);
        }
    }
</script>

<style src="@/assets/scss/_header-store.scss" lang="scss" scoped />