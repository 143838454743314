<template>
    <div class="dne-app">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="dne-app-cont">
            <div class="container">
                <h2 class="title-secondary">
                    DNE App
                </h2>

                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="download-app-figure">
                            <img src="@/assets/images/content/dne_app.png" alt="DNE App">
                        </div>
                    </div>
                    
                    <div class="col-12 col-lg-6">
                        <div class="description-app" v-html="getMyLanguage('DNEApp','description-app')"></div>

                        <div class="download-app">
                            <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                                <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                            </a>
                            
                            <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                                <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import getMyContentLanguage from "@/services/contentLanguage.js"
  
    export default {
        components: {
            Header
        },

        data () {
            return {
                page: "dne-app",
                flagSelected: "en",
            }
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-dne-app.scss" lang="scss" scoped />