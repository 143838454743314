import axios from 'axios';

const token = localStorage.getItem('accessToken');
let code = ""
let storeReference = 0

if (localStorage.getItem('storeReference') === "" || localStorage.getItem('storeReference') === null) {
    storeReference = localStorage.setItem("storeReference", 0)
} else {
    storeReference = localStorage.getItem('storeReference')
}

if (localStorage.getItem('langCode') === "" || localStorage.getItem('langCode') === null) {
    code = "en"
} else {
    code = localStorage.getItem('langCode')
}

const apiMerchant = axios.create({
    baseURL: process.env.VUE_APP_MERCHANT_API,
    headers: { 'Authorization': `bearer ${token}`, 'content-language': code, 'store-reference': storeReference, 'X-Client-Identifier': "DnePay" }
});

export default apiMerchant;