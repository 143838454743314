<template>
    <div class="sales">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />

        
        <!-- CONTENT -->
        <section class="content-sales">
            
            <div class="container">

                <!-- SALES PLUGIN -->
                <div class="cont-sales">
                    <h2 class="title-secondary subtitle">
                        {{ getMyLanguage("Sales","title") }}
                    </h2>

                    <div class="load" v-if="sectionManage.load"></div>

                    <div class="row" v-if="errorsGet.dataSalesPlugin != false">
                        <div class="col-12">
                            <div class="box box-error">
                                <h3 class="title-secondary">
                                    {{getMyLanguage("box-error","title-error")}}
                                </h3>

                                <p v-if='errorsGet.dataSalesPlugin != "401"'>
                                    {{getMyLanguage("box-error","msg-error")}}
                                </p>

                                <p v-if='errorsGet.dataSalesPlugin == "401"'>
                                    {{getMyLanguage("box-error","401")}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="sales-plugin" data-aos="fade-up" data-aos-offset="-100" v-if="!sectionManage.load && !errorsGet.dataSalesPlugin && dataSalesPlugin.length > 0">
                        <div class="sale"
                            :class="sale.transaction.operations[0].reviews[0].operation_review_status_extra"
                            v-for="(sale, index) in dataSalesPlugin"
                            :key="index"
                            @click="openModal()">
                            
                            <figure>
                                <img :src="require(`@/assets/images/icons/campaign-free-shipping.svg`)">
                            </figure>

                            <div class="cont-sale">
                                <h3>
                                    {{sale.transaction.transaction_status_reason}}
                                    <!-- <span>{{sale.transaction.operations[0].account.full_name}}</span> -->
                                </h3>

                                <div class="resume">
                                    <span class="price DNE">
                                        {{ getMyLanguage("Sales","resume.price") }}
                                        <strong>{{sale.transaction.operations[0].asset.price}}</strong>
                                    </span>

                                    <span>
                                        {{ getMyLanguage("Sales","resume.date") }}
                                        <strong>{{ dateTime(sale.transaction.created_at) }}</strong>
                                    </span>

                                    <span>
                                        {{ getMyLanguage("Sales","resume.operation") }}
                                        <strong>{{sale.transaction.operations[0].operation_type}}</strong>
                                    </span>
                                    
                                    <span>
                                        {{ getMyLanguage("Sales","resume.status") }}
                                        <strong>{{sale.transaction.operations[0].operation_status}}</strong>
                                    </span>

                                    <span>
                                        {{ getMyLanguage("Sales","resume.review") }}
                                        <strong>{{sale.transaction.operations[0].reviews[0].operation_review_status}}</strong>
                                    </span>
                                </div>

                                <div class="shipping">
                                    <p><strong>{{sale.transaction.operations[0].account.full_name}}</strong></p>
                                    <p><strong>+351 912 253 838</strong></p>
                                    <p>Alameda República, 724, Gulpilhares / Porto, Portugal, 4405-696</p>
                                </div>

                                <div class="shipping-carrier" v-if="sale.transaction.shipping">
                                    <span>{{ getMyLanguage("Account", "purchases.status." + sale.transaction.shipping.status_extra) }}</span>
                                    <span v-if="sale.transaction.shipping.status_reason">{{sale.transaction.shipping.status_reason}}</span>
                                    
                                    <span>{{ getMyLanguage("Sales","resume.shipping.carrier_name") }} <strong>{{sale.transaction.shipping.carrier_name}}</strong></span>
                                    <span>{{ getMyLanguage("Sales","resume.shipping.carrier_url") }} <a :href="sale.transaction.shipping.carrier_url" target="_blank"><strong>{{sale.transaction.shipping.carrier_url}}</strong></a></span>
                                    <span>{{ getMyLanguage("Sales","resume.shipping.tracking_number") }} <strong>{{sale.transaction.shipping.tracking_number}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-empty" v-if="!sectionManage.load && dataSalesPlugin.length == 0">
                        <h2 class="title-secondary">
                            {{ getMyLanguage("box-empty","title-empty.sales") }}
                        </h2>

                        <p>{{ getMyLanguage("box-empty","msg-empty.sales") }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL ADVANCED SEARCH -->
    <div class="modal" v-if="advancedSearch.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="advancedSearch.modal = false"></div>
            <div class="modal-scroll">
                <h2 class="title-secondary">Busca avançada</h2>
                <div class="filters">
                    filtros....
                </div>
            </div>
        </div>
    </div>


    <!-- MODAL SALE PLUGIN -->
    <div class="modal" v-if="modalPlugin.show">
        <div class="modal-cont">
            <div class="modal-close" @click="closeModal()"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary">
                    {{ getMyLanguage("Sales","update.title") }}
                </h2>

                <div class="fields">
                    <!-- STATUS -->
                    <div class="field">
                        <label>
                            <span class="info">
                                {{ getMyLanguage("Sales","status-purchase") }}
                                <i><span>{{ getMyLanguage("Sales","status-purchase.msg") }}</span></i>
                            </span>

                            <select name="" id="" class="form">
                                <option value="">{{ getMyLanguage("Sales","update.status.Active") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Inactive") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Done") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Created") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Pending") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Approved") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Paid") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Denied") }}</option>
                                <option value="">{{ getMyLanguage("Sales","update.status.Cancelled") }}</option>
                            </select>
                        </label>
                    </div>

                    <div class="bts center">
                        <div class="btn-primary">{{ getMyLanguage("Sales","btn.update-status") }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import HeaderStore from "@/components/HeaderStore.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    const html2pdf = require('html2pdf.js')

    export default {
        components: {
            Header,
            HeaderStore
        },

        data() {
            return {
                flagSelected: "en",
                page: "merchant",

                load: true,

                sectionManage: {
                    load: true,

                    loaded: {
                        sales: false,
                        plugin: false
                    }
                },
                
                dataSalesPlugin: [],

                dataMerchantStore: {},
                loadMerchantStore: true,

                storeName: "",

                errorsGet: {
                    dataSalesPlugin: false,
                },

                advancedSearch: {
                    modal: false,
                    aaa: ""
                },

                modalPlugin: {
                    show: false,
                    load: false,
                },
            }
        },

        async mounted() {
            this.languageSelected()

            // HEADER STORE
            await apiMerchant('/api/v1/market/store')
            .then(response => {
                setTimeout(() => {
                    this.dataMerchantStore = response.data.store
                    this.loadMerchantStore = false
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataMerchantStore = error.response.status
                    this.loadMerchantStore = false
                    localStorage.removeItem("storeReference")
                }, 500);
            })

            this.storeName = localStorage.getItem("storeName")


            // SALES
            this.loadSalesPlugin()
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            async loadSalesPlugin(){
                this.sectionManage.load = true

                try {
                    const responseSales = await apiMerchant.get("/api/v2/pay/tx/payments")
                    
                    this.dataSalesPlugin = responseSales.data
                    this.sectionManage.loaded.plugin = true

                    setTimeout(() => {
                        this.sectionManage.load = false
                    }, 500);
                }
                catch (error){
                    this.errorsGet.dataSalesPlugin = JSON.stringify(error.response.status)
                    this.sectionManage.load = false
                    console.error(error)

                    if ( error.response.status == 401 ){
                        bus.emit('open-login',"401");
                    }
                }
            },

            pdfPurchaseSingle(value, indexPurchase) {
                html2pdf(document.getElementById(`purchase${indexPurchase}`), {
                    filename: `Receipt ${value} DNE Market`,
                    margin: 15
                });
            },



            // PLUGIN
            openModal(){
                this.modalPlugin.show = true
                this.modalPlugin.load = true

                setTimeout(() => {
                    this.modalPlugin.load = false
                }, 500);
            },

            closeModal(){
                this.modalPlugin.show = false
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = `${this.getMyLanguage("Sales","title")} | DNE Pay`;
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    };
</script>

<style src="./custom-sales.scss" lang="scss" scoped />
