<template>
    <div class="client-space">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="client-space-home">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 col-welcome">
                        <div class="welcome">
                            <img src="~@/assets/images/header/dne-pay-white.svg" alt="DNE Pay">
                            <p><span>{{ getMyLanguage("ClientSpace","welcome.greeting") }} <strong>{{$store.state.profile.profile_full_name}}</strong></span></p>
                        </div>

                        <div class="download-app">
                            <img src="~@/assets/images/content/qrcode-download-app.png" alt="Download DNE App" class="qrcode">

                            <div class="stores">
                                <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                                </a>
                                
                                <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 col-menu-client">
                        <div class="menu-client" v-if="showContent">
                            
                            <div class="customer" :class="{isMerchant: isMerchant}">
                                <div class="legend" v-if="isMerchant">
                                    <span>{{ getMyLanguage("ClientSpace","menu-client.legend.client") }}</span>
                                </div>

                                <a :href="`/${flagSelected}/client-space/wallets`" class="menu wallets">
                                    <i></i>
                                    <strong>{{ getMyLanguage("Wallets","title") }}</strong>
                                </a>

                                <a :href="`/${flagSelected}/client-space/transactions`" class="menu transactions">
                                    <i></i>
                                    <strong>{{ getMyLanguage("Transactions","title") }}</strong>
                                </a>

                                <a :href="`/${flagSelected}/client-space/dne-app`" class="menu dne-app">
                                    <i></i>
                                    <strong>DNE App</strong>
                                </a>
                            </div>

                            <div class="merchant" :class="{isMerchant: isMerchant}" v-if="isMerchant">
                                <div class="legend">
                                    <span>{{ getMyLanguage("ClientSpace","menu-client.legend.merchant") }}</span>
                                </div>

                                <a :href="`/${flagSelected}/merchant/sales`" class="menu sales">
                                    <i></i>
                                    <strong>{{ getMyLanguage("Sales","title") }}</strong>
                                </a>

                                <a :href="`/${flagSelected}/merchant/plugin`" class="menu plugin">
                                    <i></i>
                                    <strong>Plugin</strong>
                                </a>

                                <div class="menu company" @click="lnkCompany()">
                                    <i></i>
                                    <strong>{{ getMyLanguage("Company","title") }}</strong>
                                </div>
                            </div>
                        </div>

                        <div class="download-app">
                            <div class="stores">
                                <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                                </a>
                                
                                <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>



<script>
    import Header from "@/components/Header"
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        components: {
            Header
        },

        beforeMount(){
            this.loginStatus()
        },

        data(){
            return{
                flagSelected: "en",
                showContent: false,
                
                page: "client-space",

                hasToken: "",
                token: "",

                isMerchant: !!localStorage.getItem("isMerchant"),

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                VUE_APP_PAY_URL: process.env.VUE_APP_PAY_URL,
                VUE_APP_EXCHANGE_URL: process.env.VUE_APP_EXCHANGE_URL,
            }
        },

        mounted() {
            setTimeout(() => {
                this.checkLocalStorage();
                window.addEventListener("storage", this.handleStorageChange);

                this.showContent = true
            }, 500);
        },
        
        beforeUnmount() {
            window.removeEventListener("storage", this.handleStorageChange);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space");
                document.head.querySelector('meta[name=description]').content = ''
            },

            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            lnkCompany() {
                this.newtk = localStorage.getItem('accessToken')
                const url = `${this.VUE_APP_MARKET_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=merchant/company/${localStorage.getItem('storeReference')}`
                window.open(url, '_blank')
            },

            checkLocalStorage() {
                this.isMerchant = !!localStorage.getItem("isMerchant");
            },

            handleStorageChange(event) {
                if (event.key === "isMerchant") {
                    this.checkLocalStorage();
                }
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            },
            isMerchant(newValue) {
                console.log("isMerchant:", newValue);
            }
        },

        created () {
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-home.scss" lang="scss" scoped />