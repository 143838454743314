import { createRouter, createWebHistory } from 'vue-router'

// DNE PAY
import Home from '../views/Home/Home.vue'
import DownloadDNEApp from '../views/DownloadApp/DNEApp.vue'
import TokenAuth from '../views/Account/TokenAuth.vue'
import ClientSpaceHome from '../views/ClientSpace/Home/Home.vue'
import ClientSpaceWallets from '../views/ClientSpace/Wallets/Wallets.vue'
import ClientSpaceTransactions from '../views/ClientSpace/Transactions/Transactions.vue'
import ClientSpaceTransactionsHistory from '../views/ClientSpace/Transactions/TransactionsHistory.vue'
import ClientSpaceDneApp from '../views/ClientSpace/DneApp/DneApp.vue'
import MerchantPlugin from '../views/Merchant/Plugin/Plugin.vue'
import MerchantSales from '../views/Merchant/Sales/Sales.vue'


// PLUGIN
import Plugin from '../views/PluginDnePay/Plugin/Plugin.vue'
import PluginUrlId from '../views/PluginDnePay/Plugin/Plugin.vue'
import PluginInfo from '../views/PluginDnePay/PluginInfo/Info.vue'


// PAYMENT REFERENCE
import Payment from '../views/Payment/Payment.vue'


// 403 : 404
import NotFound from '../views/NotFound/NotFound.vue'

const routes = [
  // DNE PAY
  { path: '/', name: 'redirect', redirect: '/en/', component: Home, meta: {title:'DNE Pay'} },
  { path: '/:lang/', name: 'Home', component: Home, meta: {title:'DNE Pay'} },
  { path: '/:lang/download-app', name: 'DownloadDNEApp', component: DownloadDNEApp, meta: {title:'DNE App'} },
  { path: '/:lang/account/token-auth/', name: 'TokenAuth', component: TokenAuth },
  { path: '/:lang/client-space/', name: 'ClientSpaceHome', component: ClientSpaceHome, meta: {title:'DNE Pay'} },
  { path: '/:lang/client-space/wallets', name: 'ClientSpaceWallets', component: ClientSpaceWallets, meta: {title:'DNE Pay'} },
  { path: '/:lang/client-space/transactions', name: 'ClientSpaceTransactions', component: ClientSpaceTransactions, meta: {title:'DNE Pay'} },
  { path: '/:lang/client-space/transactions/:walletType/:walletId', name: 'ClientSpaceTransactionsHistory', component: ClientSpaceTransactionsHistory, meta: {title:'DNE Pay'} },
  { path: '/:lang/client-space/dne-app', name: 'ClientSpaceDneApp', component: ClientSpaceDneApp, meta: {title:'DNE App | DNE Pay'} },
  { path: '/:lang/merchant/plugin', name: 'MerchantPlugin', component: MerchantPlugin, meta: {title:'Plugin | DNE Pay'} },
  { path: '/:lang/merchant/sales', name: 'MerchantSales', component: MerchantSales, meta: {title:'DNE Pay'} },
  

  // PLUGIN
  { path: '/plugin', name: 'Plugin', component: Plugin },
  { path: '/:lang/purchase/:urlid', name: 'PluginUrlId', component: PluginUrlId },
  { path: '/plugin-info', name: 'PluginInfo', component: PluginInfo },
  

  // PAYMENT REFERENCE
  { path: '/en/payment/:PaymentReference', name: 'Payment', component: Payment },
  
  
  // 403 : 404
  { path: '/:catchAll(.*)', component: NotFound },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title

  //Take the title from the parameters
  const titleFromParams = to.params.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // If we have a title from the params, extend the title with the title
  // from our params
  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }
  // Continue resolving the route
  next()
})


export default router
